/* Component Dependencies */
var hdVarsTemplate = require('templates/hdVars.hbs');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
var PubSub = require('libs/pub-sub');

PubSub.register(AriesComponent.extend({
	type: 'hdVars',
	template: {
		'hdVars': hdVarsTemplate
	},
	subscribe: {
		'WINDOW_LOAD_EVENT': 'invokeSessionCleanup'
	},
	bindEvents: function () {
			var _self = this,$window = $(window);
		 $window.on('load', function (event) {
		 	console.log("load completed");
				var getImg= $(".js-session-cleanup").next("img").attr("src");
				if(getImg != undefined && getImg.indexOf("crossDomainTracking.mi")){
					$(".js-session-cleanup").next("img").remove();
				}
		 });
	},
	/**
     * This function is used to make an ajax call to cleanup session
     * in the event of a cross domain navigation to ram domain
     */
	invokeSessionCleanup: function _invokeSessionCleanup() {
		var _self = this;
		var $sessionCleanup = _self.$el.find('.session-cleanup');
		if ($sessionCleanup.data('invokesessioncleanup') && $sessionCleanup.data('sessioncleanupurl')) {
			_self.makeAjaxCall({
				url: $sessionCleanup.data('sessioncleanupurl'),
				method: 'POST',
				data: JSON.stringify({'sessionToken': window.dataLayer.sessionId})
			});
		}
	}
}));
